html {
    background: #282c34;
}

#wrap {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    background: #282c34;
    color: white;
}

@media (max-width: 1000px) {
    #wrap {
        flex-direction: column;
    }
}

#left {
    flex: 1;
    text-align: center;
    padding-top: 90px;
}

#right {
    flex: 1;
    padding-top: 80px;
    padding-right: 50px;
    text-align: center;
}

.heading-para {
    font-size: x-large;
    font-style: italic;
}

.description-para {
    font-size: medium;
}

.phone {
    width: 400px;
    align-content: center;
    text-align: center;
}
