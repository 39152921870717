html {
    background: #282c34;
}

#wrap {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    background: #282c34;
    color: white;
}

@media (max-width: 800px) {
    #wrap {
        flex-direction: column;
    }
}

#left {
    flex: 1;
}

#right {
    flex: 1;
    padding-top: 70px;
    padding-left: 30px;
    padding-right: 20px;
    text-align: center;
}

.image {
    width: 90%;
    border-radius: 10px;
}
