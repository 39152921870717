.frameComp {
}

Card {
    padding-left: 50px;
}

.plantImage {
    border-radius: 25px;
    object-fit: cover;
}